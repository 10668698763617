import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { AppConstants } from '../api-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lab-bookings',
  templateUrl: './lab-bookings.component.html',
  styleUrls: ['./lab-bookings.component.css']
})
export class LabBookingsComponent implements OnInit {
  IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  accessToken;
  res: any;
  usersArray = [];
  apiBaseURL: string;
  superAdminLocalStorage;
  invalid = false;
  loader = false;
  userType;
  data: any[];
  dtOptions: DataTables.Settings = {};
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  constructor(private toastr: ToastrService, private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {
    localStorage.removeItem('selectedDoctorIdForReport');
    localStorage.removeItem('selectedStartEndDate');
      this.getAllFees();
  }

  
  getAllFees() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 20,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.loader = true;
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.accessToken);
        this.http.post(this.apiBaseURL + 'bookingRecords', dataTablesParameters, { headers })
          .subscribe(
            res => {
              this.loader = false;
              this.res = res;        
              if(this.res.status == 0){
                this.toastr.error('Your session has been expired, please login again!');
                localStorage.removeItem('timeFilterValue');
                localStorage.removeItem('dateFilterValue');
                localStorage.removeItem('selectedDoctorIdForReport');
                localStorage.removeItem('selectedStartEndDate');
                localStorage.removeItem('SuperAdminData');
                localStorage.removeItem('UserSearchType');
                this.router.navigate(['/login']); 
              }        
              this.usersArray = this.res.data;
              callback({
                recordsTotal: this.res.count,
                recordsFiltered: this.res.count,
                data: []
              });
            });
      },
    };
  }

 
  
  printBill(id) {
    this.loader = true;
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.accessToken);
      this.http.post(this.apiBaseURL + 'getBillReport', { bookingId: id}, { headers })
        .subscribe(
          res => {
            this.loader = false;
            this.res = res;
            if (this.res.status == 1) {
              window.open(this.res.data.Location, '_blank'); 
              // this.toastr.success('Successfully uploaded!');
              // this.getUserDetail();
            } else {
              this.loader = false;
              this.toastr.error('Something went wrong!');
            }
          });
  }

  
  updateStatus(id,status) {
    let type="in-progress";
    if(status == 2){
      type="completed and ready to upload reports ";
    }
    if (confirm("Do you really want to change status to "+ type+" for this booking?")) {
      this.loader = true;
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.accessToken);
      this.http.put(this.apiBaseURL + 'bookingRecords', { bookingId: id, status: status }, { headers })
        .subscribe(
          res => {
            this.loader = false;
            this.res = res;
            if (this.res.status == 1) {
              this.toastr.success('Successfully updated!');
              this.usersArray[this.usersArray.findIndex(obj => obj._id == id)].status=status;

            } else {
              this.loader = false;
              this.toastr.error('Something went wrong!');
            }
          });
    } else {

    }
  }

}

