import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { AppConstants } from '../api-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  accessToken;
  apiBaseURL: string;
  superAdminLocalStorage;


  invalid = false;
  loader = false;
  constructor(private toastr: ToastrService, private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
    if (!this.superAdminLocalStorage) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
  }

  onLogout() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'logout', {}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          localStorage.removeItem('SuperAdminData');
          this.toastr.success('Logged-out successfully!');
          this.router.navigate(['/login']);
        },
        err => {
          this.loader = false;
          //this.toastr.error('Something went wrong!');
          //console.log(err);
        },
      );
  }


}

