import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router,ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';
import { Http } from '@angular/http';
import { AppConstants } from '../api-constants';
@Component({
  selector: 'app-update-package',
  templateUrl: './update-package.component.html',
  styleUrls: ['./update-package.component.css']
})
export class UpdatePackageComponent implements OnInit {


  IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  addStudentForm: any;
  formData: any;
  selectedItems = [];
  selectedTestIds= [];
  firstName: any;
  imageUploaded= false;
  lastName: any;
  email: any;
  imageSrc: any;
  res: any;
  err: any;
  password: any;
  imagePath="";
  contactNumber: any;
  address: any;
  userRole: any;
  employeeId: any;
  cyberomId: any;
  cyberomPassword: any;
  userType: any;
  selectedClassId= "";
  userDepartment: any;
  classesArray: any;
  skypeId: any;
  apiBaseURL: any;
  userImage: any;
  superAdminLocalStorage: any;
  accessToken: any;
  submitted = false;
  invalid = false;
  dropdownSettings: any = {};
  userDetails: any;
  loader = false;
  skypePassword: any;
  joiningDate: any;
  constructor(private toastr: ToastrService,private route: ActivatedRoute,private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.IMAGE_BASE_URL = AppConstants.imageBaseUrl;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }


  ngOnInit() {
    this.getTests();
    this.getUserDetail();
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 100,
      allowSearchFilter: true
    };
  }

  getUserDetail() {   
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.loader= true;
    this.http.post(this.apiBaseURL + 'packageDetails', {_id: this.route.snapshot.params.id},{headers}).subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        this.selectedItems= [];
        this.userDetails= this.res.data;
        this.imageUploaded= true;
        this.imagePath= this.userDetails.image;
        this.selectedItems=[];

        for(let t of  this.userDetails.testIds){
          this.selectedItems.push({name: t.name, _id: t._id});
          this.selectedTestIds.push(t._id);
        }
        console.log("this.selectedItems---", this.selectedItems);
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
      }
    });
  }

  
  onItemSelect(item: any) {
    this.selectedTestIds = [];
    for (const c of this.selectedItems) {
      this.selectedTestIds.push(c._id);
    }
    console.log('this.selectedTestIds------', this.selectedTestIds);
  }


  onDeSelect(items: any) {
    this.selectedTestIds = [];
    for (const c of this.selectedItems) {
      this.selectedTestIds.push(c._id);
    }
    console.log('this.selectedTestIds------', this.selectedTestIds);
  }

  onSelectAll(items: any) {
    this.selectedItems = items;
    this.selectedTestIds = [];
    for (const c of this.selectedItems) {
      this.selectedTestIds.push(c._id);
    }
    console.log('this.selectedTestIds------', this.selectedTestIds);
  }
  onDeSelectAll(items: any) {
    this.selectedItems = items;
    this.selectedTestIds = [];
    for (const c of this.selectedItems) {
      this.selectedTestIds.push(c._id);
    }
    console.log('this.selectedTestIds------', this.selectedTestIds);
  }
    
  getTests(){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'tests', {skip:0, limit:10}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;          
          this.classesArray = this.res.data;
        });
}



  update(name: any, amount: any) {
  
    if (!name || !amount) {
      this.toastr.error('Please fill all the fields!');
      return;
    }

    if(this.selectedTestIds.length == 0){
      this.toastr.error('Please choose any of the test!');
      return;
    }
    this.loader = true;
    this.formData = {
      packageId: this.route.snapshot.params.id,
      name: name,
      image: this.imagePath,
      testIds:this.selectedTestIds
    };
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.put(this.apiBaseURL + 'package', this.formData, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        
        this.toastr.success('Successfully updated!');
       this.getUserDetail();
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });

  }

  
  processFileimage(imageInput: any) {
    this.loader = true;
    const formData: any = new FormData();
    const fileName: any = imageInput.target.files[0];
    formData.append('myfile', fileName);
    const idxDot = imageInput.target.files[0].name.lastIndexOf('.') + 1;
    const extFile = imageInput.target.files[0].name.substr(idxDot, imageInput.target.files[0].name.length).toLowerCase();
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(fileName);
      this.http.post(this.apiBaseURL+"upload", formData).subscribe(
        res => {
        this.loader = false;
        this.res= res;
        this.imagePath =this.res.Location;
        this.imageUploaded=true;
        console.log("this.imagePath",this.IMAGE_BASE_URL)
      },
        err => {
          this.loader = false;
          console.log('Error', err.error.message);
          this.err = err;
        },
      );


  }

  


}
