import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { AppConstants } from '../api-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.css']
})
export class CorporateComponent implements OnInit {

  IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  accessToken;
  res: any;
  usersArray = [];
  apiBaseURL: string;
  superAdminLocalStorage;
  invalid = false;
  loader = false;
  userType;
  data: any[];
  dtOptions: DataTables.Settings = {};
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  constructor(private toastr: ToastrService, private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {
    localStorage.removeItem('selectedDoctorIdForReport');
    localStorage.removeItem('selectedStartEndDate');
      this.getAllFees();
  }


  
  getAllFees() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.loader = true;
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.accessToken);
        this.http.post(this.apiBaseURL + 'hospitals', dataTablesParameters, { headers })
          .subscribe(
            res => {
              this.loader = false;
              this.res = res;          
              this.usersArray = this.res.data;
              callback({
                recordsTotal: this.res.count,
                recordsFiltered: this.res.count,
                data: []
              });
            });
      },
    };
  }


  
  
  delete(id) {
    if (confirm('Do you really want to delete this corporate?')) {
      this.loader = true;
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.accessToken);
      this.http.put(this.apiBaseURL + 'hospitals', { doctorId: id, isDeleted: 1 }, { headers })
        .subscribe(
          res => {
            this.loader = false;
            this.res = res;
            if (this.res.status == 1) {
              this.toastr.success('Successfully deleted!');
              this.usersArray = this.usersArray.filter(function( obj ) {
                return obj._id !== id;
              });
            } else {
              this.loader = false;
              this.toastr.error('Something went wrong!');
            }
          });
    } else {

    }
  }
  
  updateStatus(id,status) {
    let type="activate";
    if(status == 0){
      type="de-activate";
    }
    if (confirm("Do you really want to "+ type+" this corporate?")) {
      this.loader = true;
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.accessToken);
      this.http.put(this.apiBaseURL + 'hospitals', { doctorId: id, isActive: status }, { headers })
        .subscribe(
          res => {
            this.loader = false;
            this.res = res;
            if (this.res.status == 1) {
              this.toastr.success('Successfully updated!');
              this.usersArray[this.usersArray.findIndex(obj => obj._id == id)].isActive=status;
              console.log(" this.usersArray--", this.usersArray)
            } else {
              this.loader = false;
              this.toastr.error('Something went wrong!');
            }
          });
    } else {

    }
  }


}

