import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Http } from '@angular/http';
import { AppConstants } from '../api-constants';
@Component({
  selector: 'app-add-test',
  templateUrl: './add-test.component.html',
  styleUrls: ['./add-test.component.css']
})
export class AddTestComponent implements OnInit {

 IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  addStudentForm: any;
  selectedItems = [];
  selectedUsers= [];
  formData: any;
  firstName: any;
  imageUploaded= false;
  lastName: any;
  email: any;
  imageSrc: any;
  res: any;
  err: any;
  password: any;
  imagePath="";
  contactNumber: any;
  address: any;
  userRole: any;
  employeeId: any;
  cyberomId: any;
  cyberomPassword: any;
  userType: any;
  selectedCateoryId: any;
  selectedClassId= "";
  userDepartment: any;
  classesArray: any;
  skypeId: any;
  apiBaseURL: any;
  userImage: any;
  superAdminLocalStorage: any;
  accessToken: any;
  submitted = false;
  invalid = false;
  loader = false;
  dropdownSettings: any = {};
  skypePassword: any;
  joiningDate: any;
  constructor(private toastr: ToastrService,private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {
    this.getCategories();
    this.dropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'name',
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      itemsShowLimit: 100,
      allowSearchFilter: true
    };
  }


  onItemSelect(item: any) {
    this.selectedUsers = [];
    for (const c of this.selectedItems) {
      this.selectedUsers.push(c._id);
      this.selectedCateoryId= c._id
    }
  }
  onDeSelect(item: any) {
    this.selectedCateoryId= "";
  }


  
  getCategories(){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'category', {skip:0, limit:10}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;          
          this.classesArray = this.res.data;
        });
}


  add(name: any, amount: any) {

    if (!name || !amount || !this.selectedCateoryId) {
      this.toastr.error('Please fill all the fields!');
      return;
    }
    this.loader = true;
    this.formData = {
      name: name,
      amount: amount,
      categoryId: this.selectedCateoryId
    };
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'addTest', this.formData, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        this.toastr.success('Successfully Added!');
        this.router.navigate(['/tests']);
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });

  }

}
