import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Http } from '@angular/http';
import { AppConstants } from '../api-constants';
@Component({
  selector: 'app-add-booking',
  templateUrl: './add-booking.component.html',
  styleUrls: ['./add-booking.component.css']
})
export class AddBookingComponent implements OnInit {


IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  addStudentForm: any;
  formData: any;
  selectedItems = [];
  selectedItemsNewTests = [];
  selectedItemsPatients = [];
  selectedItemsPackages = [];
  selectedItemsCorporate = [];
  selectedItemsDoctors = [];
  selectedTestIds= [];
  firstName: any;
  imageUploaded= false;
  lastName: any;
  email: any;
  imageSrc: any;
  res: any;
  err: any;
  password: any;
  imagePath="";
  contactNumber: any;
  address: any;
  userRole: any;
  paymentModeCashOnlineSelected= 0;
  employeeId: any;
  cyberomId: any;
  cyberomPassword: any;
  userType: any;
  selectedClassId= "";
  selectedPatientId= "";
  selectedPackageId= "";
  selectedDoctorId= "";
  selectedCorporateId= "";
  addNewTest= false;
  userDepartment: any;
  patientsArray: any;
  finalRequest: any;
  testsArray: any;
  packagesArray: any;
  corporateArray: any;
  doctorsArray: any;
  skypeId: any;
  apiBaseURL: any;
  userImage: any;
  superAdminLocalStorage: any;
  accessToken: any;
  genderSelected= 1;
  refferedSelected= 1;
  selectedValueReffered=1;
  selectedValueDiscounted= 0;
  submitted = false;
  selectedDiscountType= "";
  selectedGender= "Male";
  invalid = false;
  dropdownSettings: any = {};
  dropdownSettingsNewTest: any = {};
  dropdownSettingsPackage: any = {};
  dropdownSettingsPatient: any = {};
  dropdownSettingsDoctor: any = {};
  dropdownSettingsCorporate: any = {};

  userDetails: any = {};
  doctorDetails: any = {};
  corporateDetails: any = {};
  loader = false;
  skypePassword: any;

  dueAmountTotal=0;
  dueAmountFinal=0;
  amountFirst=0;
  cashAmount= 0;
  onlineAmount= 0;
  payableAmount=0;
  discountValue=0;
  finalPayableAmount=0;
  selectedValue= 1;
  selectedCateoryId: any;
  newTestName= "";
  newTestAmount= "";


  patientObj: any;
  doctorObj: any;
  corporateObj: any;

  classesArray=[];
  patientDetail=[];
  doctorDetail=[];
  corporateDetail=[];
  selectedValuePaymentMode=1;

  joiningDate: any;
  refferedArray=[{name:"Self", value:1},{name:"Doctor", value:3}];
  genderArray=[{name:"Male", value:1},{name:"Female", value:2},{name:"Other", value:3}];
  mrMrsArray=[{name:"Mr.", value:1},{name:"Mrs.", value:2},{name:"Ms.", value:3}];
  paymentModeArray=[{name:"Cash", value:1},{name:"Online", value:2},{name:"Cash/Online", value:3},{name:"Card", value:4},{name:"Credit", value:5}];
  discountArray=[{name:"Amount", value:1},{name:"Percentage", value:2}];
  constructor(private toastr: ToastrService,private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {
    localStorage.removeItem('selectedDoctorIdForReport');
    localStorage.removeItem('selectedStartEndDate');
    localStorage.removeItem('selectedStartEndDateBookingPage');
    this.getTests();
    this.getPackages();
    this.getPatients();
   // this.getCorporates();
    this.getDoctors();
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
    this.dropdownSettingsNewTest = {
      singleSelection: true,
      idField: '_id',
      textField: 'name',
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      itemsShowLimit: 100,
      allowSearchFilter: true
    };
    this.dropdownSettingsPackage = {
      singleSelection: true,
      idField: '_id',
      textField: 'name',
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.dropdownSettingsPatient = {
      singleSelection: true,
      idField: '_id',
      textField: 'custom',
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.dropdownSettingsDoctor = {
      singleSelection: true,
      idField: '_id',
      textField: 'firstName',
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
   
  }

  addNewTestButtonClick(){
    this.getCategories();
    this.addNewTest= true;
  }

  onCashAmountChange(value){
    this.cashAmount= value;
  }
  onOnlineAmountChange(value){
    this.onlineAmount= value;
  }


  onAdvancePaymentChange(value){
    if(value > this.dueAmountTotal){
      this.toastr.error('Advance amount should be less than due amount!'); 
    }
    else{
    this.dueAmountFinal= this.dueAmountTotal - value;
   
    }
  }

  onDiscountValueChange(value){
    console.log("onDiscountValueChange---", value)
    if(value < 0){
      this.toastr.error('Discount value should not be less than 0!'); 
    }
    else{
    if(this.selectedValueDiscounted == 1){  
      if(value > this.payableAmount){
      this.toastr.error('Discount value should be less than payable amount!');
    }
    else{
      this.finalPayableAmount= this.payableAmount - value;
      this.discountValue= parseInt(value);
      this.dueAmountFinal= this.finalPayableAmount;
      this.dueAmountTotal= this.finalPayableAmount;
    }

  }
    if(this.selectedValueDiscounted == 2){
      if((value > this.payableAmount) && (value > 99)){
      this.toastr.error('Discount percentage value should be less than payable amount!');
    }
    else{
      this.discountValue= ((value / 100) * this.payableAmount);
      this.finalPayableAmount= this.payableAmount - ((value / 100) * this.payableAmount);
      this.dueAmountFinal= this.finalPayableAmount;
      this.dueAmountTotal= this.finalPayableAmount;
    }
  }
}

  }

  onValueChange(event){
    this.genderSelected= event.target.value;
  }
  onValueChangeReffered(event){
    this.refferedSelected= event.target.value;
  }
  onValueChangeDiscountType(event){
    this.selectedValueDiscounted= event.target.value;
    if(this.selectedValueDiscounted == 1){
      this.selectedDiscountType= " in Amount";
    }
    if(this.selectedValueDiscounted == 2){
      this.selectedDiscountType= " in Percentage";
    }
  }

  onItemSelectPackage(item: any) {
    //this.selectedTestIds = [];
    for (const c of this.selectedItemsPackages) {
      this.selectedPackageId=c._id;
      this.selectedItems=[];
      this.addNewTest= false;
      this.selectedTestIds=[];
    }
    this.getAmount([this.selectedPackageId],"Packages");
    console.log('this.selectedPackageId------', this.selectedPackageId);
  }


  onDeSelectPackage(items: any) {
    //this.selectedTestIds = [];
    for (const c of this.selectedItemsPackages) {
      this.selectedPackageId=c._id;
      this.selectedItems=[];
      this.selectedTestIds=[];
      this.addNewTest= false;
    }
    if(this.selectedItemsPackages.length > 0){
      this.getAmount([this.selectedPackageId],"Packages");
    }
    else{
      this.selectedPackageId="";
      this.addNewTest= false;
      this.amountFirst=0;
      this.payableAmount=0;
      this.finalPayableAmount=0;
      this.selectedItems=[];
      this.selectedTestIds=[];
    }
    console.log('this.selectedPackageId------', this.selectedPackageId);
  }


  onItemSelectPatient(item: any) {
    //this.selectedTestIds = [];
    this.patientDetail=[];
    for (const c of this.selectedItemsPatients) {
      this.selectedPatientId=c._id;
      this.getDetails(this.selectedPatientId,"Patient");
    }
    console.log('this.selectedPatientId------', this.selectedPatientId);
  }


  onDeSelectPatient(items: any) {
    //this.selectedTestIds = [];
    for (const c of this.selectedItemsPatients) {
      this.selectedPatientId=c._id;
      this.patientDetail=[];
      this.userDetails={};
    }
    this.userDetails={};
    this.selectedPatientId="";
    this.patientDetail=[];
    this.userDetails={};
    console.log('this.selectedPatientId------', this.selectedPatientId);
  }

  

  onItemSelectCorporate(item: any) {
    //this.selectedTestIds = [];
    for (const c of this.selectedItemsCorporate) {
      this.selectedCorporateId=c._id;
      this.getDetails(this.selectedCorporateId,"Corporate");
    }
    this.doctorObj={};
    console.log('this.selectedCorporateId------', this.selectedCorporateId);
  }


  onDeSelectCorporate(items: any) {
    //this.selectedTestIds = [];
    for (const c of this.selectedItemsCorporate) {
      this.selectedCorporateId="";
    }
    this.selectedCorporateId="";
    this.corporateDetails={
      name: "",
      email: "",
      mobileNumber: "",
      address: ""
    };
    console.log('this.selectedCorporateId------', this.selectedCorporateId);
    console.log('this.this.corporateDetails------', this.corporateDetails);
  }

  
  onItemSelectDoctors(item: any) {
    //this.selectedTestIds = [];
    for (const c of this.selectedItemsDoctors) {
      this.selectedDoctorId=c._id;
      this.getDetails(this.selectedDoctorId,"Doctor");
    }
    this.corporateObj={};
    console.log('this.selectedDoctorId------', this.selectedDoctorId);
  }


  onDeSelectDoctors(items: any) {
    //this.selectedTestIds = [];
    for (const c of this.selectedItemsDoctors) {
      this.selectedDoctorId=c._id;
    }
    this.selectedDoctorId="";
    
    this.doctorDetails={
      firstName: "",
      email: "",
      mobileNumber: "",
    };
    console.log('this.selectedDoctorId------', this.selectedDoctorId);
    console.log('this.doctorDetails------', this.doctorDetails);
  }

  
  onItemSelect(item: any) {
    this.selectedTestIds = [];
    for (const c of this.selectedItems) {
      this.selectedPackageId= "";
      this.selectedItemsPackages=[];
      this.selectedTestIds.push(c._id);
    }
    this.getAmount(this.selectedTestIds,"Tests");
    console.log('this.selectedTestIds------', this.selectedTestIds);
  }


  onDeSelect(items: any) {
    this.selectedTestIds = [];
    for (const c of this.selectedItems) {
      this.selectedPackageId= "";
      this.selectedItemsPackages=[];
      this.selectedTestIds.push(c._id);
    }
    if(this.selectedTestIds.length > 0){
    this.getAmount(this.selectedTestIds,"Tests");
    }
    else{
      this.amountFirst=0;
      this.payableAmount=0;
      this.finalPayableAmount=0;
      this.dueAmountTotal=0;
      this.dueAmountFinal=0;
    }
    console.log('this.selectedTestIds------', this.selectedTestIds);
  }


  getDetails(id, type){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    if(type == "Patient"){
      this.loader = true;
    this.http.post(this.apiBaseURL + 'getDetails', {id:id, type:type}, { headers })
    .subscribe(
      res => {
        this.loader = false;
        this.res = res;          
        this.userDetails = this.res.data[0];
        this.selectedValue= this.userDetails.gender;
        console.log("this.userDetails------",this.userDetails)
      });
    }

    if(type == "Doctor"){
      this.loader = true;
      this.http.post(this.apiBaseURL + 'getDetails', {id:id, type:type}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;          
          this.doctorDetails = this.res.data[0];
          this.doctorObj={
            _id: this.doctorDetails._id,
            name:  this.doctorDetails.firstName,
            email:  this.doctorDetails.email,
            mobileNumber:  this.doctorDetails.mobileNumber
          };
        console.log("this.doctorDetails------",this.doctorDetails)
        });
      }

      if(type == "Corporate"){
        this.loader = true;
        this.http.post(this.apiBaseURL + 'getDetails', {id:id, type:type}, { headers })
        .subscribe(
          res => {
            this.loader = false;
            this.res = res;          
            this.corporateDetails= this.res.data[0];
            this.corporateObj={
              _id: this.corporateDetails._id,
              name:  this.corporateDetails.name,
              email:  this.corporateDetails.email,
              mobileNumber:  this.corporateDetails.mobileNumber,
              address:  this.corporateDetails.address
            };
            console.log("this.corporateDetails------",this.corporateDetails)
          });
        }

  }


    
  getCategories(){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'category', {skip:0, limit:10}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;          
          this.classesArray = this.res.data;
        });
}

onItemSelectTest(item: any) {
  for (const c of this.selectedItemsNewTests) {
    this.selectedCateoryId= c._id
  }
}
onDeSelectTest(item: any) {
    this.selectedCateoryId= "";
}

  
addNewTestFunction(name: any, amount: any) {

    if (!name || !amount || !this.selectedCateoryId) {
      this.toastr.error('Please fill all the fields!');
      return;
    }
    this.loader = true;
    this.formData = {
      name: name,
      amount: amount,
      categoryId: this.selectedCateoryId
    };
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'addTest', this.formData, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        this.getTests();
        this.toastr.success('Successfully Added New Test!');
        this.newTestName="";
        this.newTestAmount="";
        this.selectedCateoryId= "";
        this.addNewTest= false;
        this.selectedItemsNewTests=[];
        this.selectedTestIds.push(this.res.data._id);
        console.log("this.selectedTestIds2222222----------",this.selectedTestIds)
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });

  }
  
  addCorporate(name: any, email: any, mobileNumber: any, address : any) {
    if (!name) {
      this.toastr.error('Please fill name in the corporate details!');
      return;
    }
    this.corporateObj= {};
    this.doctorObj={};
    this.corporateObj = {
      _id: this.selectedCorporateId,
      name: name,
      email: email,
      address: address,
      mobileNumber: mobileNumber,
    };
    console.log("this.corporateObj =",this.corporateObj );
  }
  addDoctor(name: any, email: any, mobileNumber: any) {
    if (!name) {
      this.toastr.error('Please fill name in the doctor details!');
      return;
    }
    this.doctorObj={};
    this.corporateObj={};
    this.doctorObj = {
      _id: this.selectedDoctorId,
      name: name,
      email: email,
      mobileNumber: mobileNumber,
    };
    console.log("this.doctorObj =",this.doctorObj );
  }

    
  getTests(){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'tests', {skip:0, limit:10}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;          
          this.testsArray = this.res.data;
        });
}
    
getAmount(ids, type){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'getAmount', {ids:ids, type:type}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;     
          this.amountFirst= this.res.data[0].totalAmount;
          this.payableAmount= this.res.data[0].totalAmount;
          this.finalPayableAmount= this.res.data[0].totalAmount;
          this.dueAmountTotal= this.res.data[0].totalAmount;
          this.dueAmountFinal= this.res.data[0].totalAmount;
          this.discountValue= 0;
        });
}
  getPackages(){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'package', {skip:0, limit:10}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;          
          this.packagesArray = this.res.data;
        });
}
getPatients(){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'patients', {skip:0, limit:10}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;          
          this.patientsArray = this.res.data;
          this.patientsArray = this.patientsArray.map((v: any) => ({...v, custom: v.firstName+" - "+v.mobileNumber +" - "+v.userId}));
          console.log("this.patientsArray---",this.patientsArray)
        });
}
getCorporates(){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'hospitals', {skip:0, limit:10}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;          
          this.corporateArray = this.res.data;
        });
}
getDoctors(){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'doctors', {skip:0, limit:100, type:"all"}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;          
          this.doctorsArray = this.res.data;
        });
}

clearData(){
  if (confirm('Do you really want to clear all the fields?')) {
  window.location.reload();
  }
}

onValueChangePaymentMode(event){
  this.selectedValuePaymentMode= event.target.value;
  if(this.selectedValuePaymentMode == 3){
    this.paymentModeCashOnlineSelected= 1;
  }
}

onValueChangeMrMrsArray(event){
  if(event.target.value == 1){
    this.genderSelected=1;
    this.selectedGender="Male";
    this.selectedValue = 1;
  }
  else{
    this.genderSelected=2;
    this.selectedGender="Female";
    this.selectedValue = 2;
  }
}

//   dname: any,demail: any,dmobileNumber: any,advanceAmount:any,dueAmount: any
add(name: any,email: any,age: any,address: any,mobileNumber: any,advanceAmount:any,dueAmount: any) {

  if(name == "" || mobileNumber =="" || age =="" || mobileNumber.length !== 10){
    this.toastr.error('Please enter patient name, valid mobile number and age!');
    return;
  }
    this.finalRequest={};
    this.patientObj={};
    this.patientObj._id= this.selectedPatientId;
    this.patientObj.name= name;
    this.patientObj.mobileNumber= mobileNumber;
    this.patientObj.email= email;
    this.patientObj.address= address;
    this.patientObj.age= age;
    this.patientObj.gender= this.genderSelected;
    this.finalRequest.patientObject=this.patientObj; 
    this.finalRequest.doctorObject=this.doctorObj; 
    this.finalRequest.corporateObject=this.corporateObj; 
   
    this.finalRequest.advanceAmount= advanceAmount || 0;
    this.finalRequest.dueAmount= dueAmount || 0;
    if(this.selectedValuePaymentMode == 1){
      this.finalRequest.cashAmount=this.finalPayableAmount || 0;
      this.finalRequest.onlineAmount=0;
    }
    else if(this.selectedValuePaymentMode == 2 || this.selectedValuePaymentMode == 4){
      this.finalRequest.onlineAmount=this.finalPayableAmount || 0;
      this.finalRequest.cashAmount=0;
    }
    else if(this.selectedValuePaymentMode == 5 ){
      this.finalRequest.dueAmount= this.finalPayableAmount || 0;
      this.finalRequest.cashAmount=0;
      this.finalRequest.onlineAmount=0;
      this.finalRequest.advanceAmount= 0;
    }
    else{
      this.finalRequest.onlineAmount= this.onlineAmount || 0;
      this.finalRequest.cashAmount= this.cashAmount || 0;
    }   
    this.finalRequest.paymentMode= this.selectedValuePaymentMode;
    this.finalRequest.amountFirst= this.amountFirst || 0;
    this.finalRequest.payableAmount= this.payableAmount || 0;
    this.finalRequest.discountValue= this.discountValue || 0;
    this.finalRequest.finalPayableAmount= this.finalPayableAmount || 0;
    this.finalRequest.testIds= this.selectedTestIds;
    this.finalRequest.packageId=this.selectedPackageId;
   
    if(this.selectedTestIds.length == 0 && this.selectedPackageId == ""){
      this.toastr.error('Please choose any of the test or package!');
      return;
    }

    console.log(" this.finalRequest------",  this.finalRequest);
    this.loader = true;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'createBookingV2', this.finalRequest, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        this.toastr.success('Successfully Added!');
        this.router.navigate(['/bookings']);
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });

  }
}
