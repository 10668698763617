import { environment } from '../../environments/environment';

let APIEndpoint = environment.APIEndpoint;
let IMAGEBASEURL = environment.IMAGE_BASE_URL;

//console.log('this.appService.getHostname()', window.location.hostname);
if (window.location.hostname === 'localhost') {
  APIEndpoint = environment.APIEndpoint;
  IMAGEBASEURL = environment.IMAGE_BASE_URL;

} else if (window.location.hostname === 'admin.mysmugglers.com') {
  APIEndpoint = environment.APIEndpoint;
  IMAGEBASEURL = environment.IMAGE_BASE_URL;

} else {
  APIEndpoint = environment.APIEndpoint;
  IMAGEBASEURL = environment.IMAGE_BASE_URL;

}

export class AppConstants {
  public static get baseURL(): string { return APIEndpoint + 'v1/admin/'; }

  public static get imageBaseUrl() {
    return IMAGEBASEURL;
  }

}
