import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { AppConstants } from '../api-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {



  IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  accessToken;
  res: any;
  usersArray = [];
  apiBaseURL: string;
  superAdminLocalStorage;
  invalid = false;
  loader = false;
  userType;
  data: any[];
  dtOptions: DataTables.Settings = {};
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  constructor(private toastr: ToastrService, private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {
      this.getAllFees();
  }


  
  getAllFees() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.loader = true;
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.accessToken);
        this.http.post(this.apiBaseURL + 'activitesList', dataTablesParameters, { headers })
          .subscribe(
            res => {
              this.loader = false;
              this.res = res;    
              if(this.res.status == 0){
                this.toastr.error('Your session has been expired, please login again!');
                localStorage.removeItem('timeFilterValue');
                localStorage.removeItem('dateFilterValue');
                localStorage.removeItem('selectedDoctorIdForReport');
                localStorage.removeItem('selectedStartEndDate');
                localStorage.removeItem('SuperAdminData');
                localStorage.removeItem('UserSearchType');
                this.router.navigate(['/login']); 
              }      
              this.usersArray = this.res.data;
              callback({
                recordsTotal: this.res.count,
                recordsFiltered: this.res.count,
                data: []
              });
            });
      },
    };
  }
}

