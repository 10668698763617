import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router,ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';
import { Http } from '@angular/http';
import { AppConstants } from '../api-constants';
@Component({
  selector: 'app-lab-booking-details',
  templateUrl: './lab-booking-details.component.html',
  styleUrls: ['./lab-booking-details.component.css']
})
export class LabBookingDetailsComponent implements OnInit {


  addStudentForm: any;
  formData: any;
  imageUploaded1= false;
  imageUploaded2= false;
  imageUploaded3= false;
  imageUploaded4= false;
  imagePath1="";
  imagePath2="";
  imagePath3="";
  reportsArray= [];
  imagePath4="";
  firstName: any;
  fullPath: any;
  testIds="";
  paymentMode="Cash";
  bookingBy="Admin";
  lastName: any;
  email: any;
  imageSrc: any;
  res: any;
  err: any;
  password: any;
  contactNumber: any;
  address: any;
  userRole: any;
  employeeId: any;
  cyberomId: any;
  cyberomPassword: any;
  selectedClassId ="";
  doctorName ="";
  hospitalName ="";
  selectedStaffId ="";
  selectedAssistantStaffId ="";
  selectedValue= "";
  selectedInchargeValue= "";
  userType: any;
  IMAGE_BASE_URL:any;
  userDepartment: any;
  skypeId: any;
  apiBaseURL: any;
  userDetails: any;
  classesArray: any;
  staffArray: any;
  userImage: any;
  imagePath: any;
  superAdminLocalStorage: any;
  accessToken: any;
  submitted = false;
  invalid = false;
  loader = false;
  skypePassword: any;
  joiningDate: any;
  constructor(private toastr: ToastrService,private route: ActivatedRoute,private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.IMAGE_BASE_URL = AppConstants.imageBaseUrl;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {
    localStorage.removeItem('selectedDoctorIdForReport');
    localStorage.removeItem('selectedStartEndDate');
    this.getUserDetail();
  }


  getUserDetail() {   
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.loader= true;
    this.http.post(this.apiBaseURL + 'getBookingDetails', {_id: this.route.snapshot.params.id},{headers}).subscribe(
      res => {
      this.loader = false;
      this.res= res;
      this.testIds= "";
      this.doctorName= "";
      this.bookingBy= "Admin";
      this.paymentMode="Cash";
      if (this.res.status == 1) {
        this.userDetails= this.res.data;
        if(this.userDetails.doctorId !== undefined){
          this.doctorName=this.userDetails.doctorId.firstName;
        }
        if(this.userDetails.hospitalId !== undefined){
          this.hospitalName=this.userDetails.hospitalId.name;
        }
        if(this.userDetails.isAdminBooking == 2){
          this.bookingBy="Patient";
        }
        if(this.userDetails.paymentMode == 2){
          this.paymentMode="Online";
        }
        if(this.userDetails.paymentMode == 3){
          this.paymentMode="Cash/Online";
        }
        if(this.userDetails.paymentMode == 4){
          this.paymentMode="Card";
        }
        if(this.userDetails.paymentMode == 5){
          this.paymentMode="Credit";
        }

        this.reportsArray= this.userDetails.reports;
        // if(this.userDetails.testIds.length > 0){
        //   this.testIds= this.userDetails.testIds.map((obj: { name: any; }) => obj.name).toString();
        // }
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
      }
    });
  }


  udpateStatus(status) {
    let type="in-progress";
    if(status == 2){
      type="completed and ready to upload reports";
    }
    if (confirm("Do you really want to set booking status to "+ type+" ?")) {
      this.loader = true;
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.accessToken);
      this.http.put(this.apiBaseURL + 'bookingRecords', { bookingId: this.route.snapshot.params.id, status: status }, { headers })
        .subscribe(
          res => {
            this.loader = false;
            this.res = res;
            if (this.res.status == 1) {
              this.toastr.success('Successfully updated!');
              this.getUserDetail();
            } else {
              this.loader = false;
              this.toastr.error('Something went wrong!');
            }
          });
    } else {

    }
  }

  addReport(name: any) {
    if (!name || !this.imagePath1) {
      this.toastr.error('Please fill the report name field and upload pdf file!');
      return;
    }
    this.loader = true;
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.accessToken);
      this.http.put(this.apiBaseURL + 'bookingRecords', { bookingId: this.route.snapshot.params.id, reports: {name: name, link: this.imagePath1} }, { headers })
        .subscribe(
          res => {
            this.loader = false;
            this.res = res;
            if (this.res.status == 1) {
              this.toastr.success('Successfully uploaded!');
             window.location.reload();
            } else {
              this.loader = false;
              this.toastr.error('Something went wrong!');
            }
          });
  }



  processFileimage1(imageInput: any) {
   
    const formData: any = new FormData();
    const fileName: any = imageInput.target.files[0];
    formData.append('myfile', fileName);
    const idxDot = imageInput.target.files[0].name.lastIndexOf('.') + 1;
    const extFile = imageInput.target.files[0].name.substr(idxDot, imageInput.target.files[0].name.length).toLowerCase();
    if(extFile !== "pdf"){
      this.toastr.error('Please upload PDF file only!');
    }
    else{
      this.loader = true;
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(fileName);
      this.http.post(this.apiBaseURL+"upload", formData).subscribe(
        res => {
        this.loader = false;
        this.res= res;
        this.imagePath1 =this.res.Location;
        this.imageUploaded1=true;
      },
        err => {
          this.loader = false;
          console.log('Error', err.error.message);
          this.err = err;
        }
      )
    }
      
  }




}


