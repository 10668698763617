import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import { Http } from '@angular/http';
import { AppConstants } from '.././api-constants';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  accessToken;
  userType= 1;
  apiBaseURL: string;
  superAdminLocalStorage;
  invalid = false;
  res : any;
  loader = false;
  constructor(private toastr: ToastrService, private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
    this.userType= this.superAdminLocalStorage.type;
    if (!this.superAdminLocalStorage) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
   
  }
  onLogout() {
    console.log("logout calling")
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    console.log("headers--", headers)
    this.http.post(this.apiBaseURL + 'logout', {}, { headers })
      .subscribe(
        res => {
          this.res= res;
      if (this.res.status === 1) {
          this.loader = false;
          localStorage.removeItem('timeFilterValue');
          localStorage.removeItem('dateFilterValue');
          localStorage.removeItem('selectedDoctorIdForReport');
          localStorage.removeItem('selectedStartEndDate');
          localStorage.removeItem('SuperAdminData');
          localStorage.removeItem('UserSearchType');
          this.toastr.success('Logged-out successfully!');
          this.router.navigate(['/login']);
        }
        else{
          this.loader = false;
        this.toastr.error('Error in logout!');
        this.invalid = true;
        }
      });
  }

}
