import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { AppConstants } from '../api-constants';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { Daterangepicker } from 'ng2-daterangepicker';


@Component({
  selector: 'app-com-bookings',
  templateUrl: './com-bookings.component.html',
  styleUrls: ['./com-bookings.component.css']
})
export class ComBookingsComponent implements OnInit {


    daterange: any = {};
    options: any = {
      locale: { format: 'DD-MM-YYYY' },
      alwaysShowCalendars: false,
    };
  commisionAmount: any;
  bookigId: any;
   
    selectedDate(value: any, datepicker?: any) {
      // any object can be passed to the selected event and it will be passed back here
      datepicker.start = value.start;
      datepicker.end = value.end; 
      // use passed valuable to update state
      this.daterange.start = moment(new Date(value.start._d)).format("DD/MM/YYYY");
      this.daterange.end =  moment(new Date(value.end._d)).format("DD/MM/YYYY");
      localStorage.setItem('selectedStartEndDateCommisionPage', JSON.stringify({startDate: this.daterange.start, endDate:this.daterange.end}));
      window.location.reload();
  
    } 
  
 IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  accessToken;
  res: any;
  optionObj: any;
  usersArray = [];
  apiBaseURL: string;
  selectedDateRangeLocalBookingPage: any;
  superAdminLocalStorage;
  invalid = false;
  loader = false;
  comObjArray : any;
  selectedDateRangeLocal: any;
  userType;
  data: any[];
  dtOptions: DataTables.Settings = {};
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  constructor(private toastr: ToastrService, private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {
    this.selectedDateRangeLocalBookingPage= JSON.parse(localStorage.getItem('selectedStartEndDateCommisionPage'));
    if(this.selectedDateRangeLocalBookingPage){
      this.getAllFees(this.selectedDateRangeLocalBookingPage.startDate, this.selectedDateRangeLocalBookingPage.endDate);
    }
    else{
      this.getAllFees("","");
    }
  }


      
  clearData(){
    localStorage.removeItem('selectedStartEndDateCommisionPage');
    window.location.reload();
  }

  
  downloadReport(){
    this.loader = true;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.selectedDateRangeLocal= JSON.parse(localStorage.getItem('selectedStartEndDateCommisionPage'));
    this.optionObj={};
    if(this.selectedDateRangeLocal){
      this.optionObj.startDate= this.selectedDateRangeLocal.startDate;
      this.optionObj.endDate= this.selectedDateRangeLocal.endDate;
    }

    this.http.post(this.apiBaseURL + 'downloadReportCommsions', this.optionObj, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        window.open(this.res.data.Location, '_blank'); 
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });
  }


  saveCommsion(){
console.log("this.comObjArray--",this.comObjArray)
    this.loader = true;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.put(this.apiBaseURL + 'updateCommisionForBookings', { bookingsArray: this.comObjArray}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;
          if (this.res.status == 1) { 
             this.toastr.success('Successfully updated!');
             this.getAllFees("","");
          } else {
            this.loader = false;
            this.toastr.error('Something went wrong!');
          }
        });
  }

  onCommsionValueCahnges(value,index){
    
    if(!value){
      value= 0;
    }
    console.log("value,index",value,index)
this.comObjArray[index].commisionAmount= value;
  }

  
  getAllFees(startDate="", endDate="") {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 20,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.loader = true;
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.accessToken);
        if(startDate !== ""){
          dataTablesParameters.startDate= startDate;
        }
        if(endDate !== ""){
          dataTablesParameters.endDate= endDate;
        }
        this.http.post(this.apiBaseURL + 'bookingRecords', dataTablesParameters, { headers })
          .subscribe(
            res => {
              this.loader = false;
              this.res = res;          
              this.usersArray = this.res.data;
              this.comObjArray= this.usersArray.map((obj) =>   {
                return { commisionAmount: obj.commisionAmount, bookingId: obj._id };
              });
              console.log("this.comObjArray---",this.comObjArray)
              callback({
                recordsTotal: this.res.count,
                recordsFiltered: this.res.count,
                data: []
              });
            });
      },
    };
  }

 
  
  printBill(id) {
    this.loader = true;
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.accessToken);
      this.http.post(this.apiBaseURL + 'getBillReport', { bookingId: id}, { headers })
        .subscribe(
          res => {
            this.loader = false;
            this.res = res;
            if (this.res.status == 1) {
              window.open(this.res.data.Location, '_blank'); 
              // this.toastr.success('Successfully uploaded!');
              // this.getUserDetail();
            } else {
              this.loader = false;
              this.toastr.error('Something went wrong!');
            }
          });
  }


}

