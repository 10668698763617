import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Http } from '@angular/http';
import { AppConstants } from '../api-constants';

@Component({
  selector: 'app-add-doctor',
  templateUrl: './add-doctor.component.html',
  styleUrls: ['./add-doctor.component.css']
})
export class AddDoctorComponent implements OnInit {


 IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  addStudentForm: any;
  formData: any;
  firstName: any;
  imageUploaded= false;
  lastName: any;
  email: any;
  genderSelected: any;
  imageSrc: any;
  res: any;
  err: any;
  password: any;
  imagePath="";
  contactNumber: any;
  address: any;
  userRole: any;
  employeeId: any;
  cyberomId: any;
  cyberomPassword: any;
  userType: any;
  selectedCateoryId: any;
  selectedClassId= "";
  userDepartment: any;
  classesArray: any;
  skypeId: any;
  apiBaseURL: any;
  userImage: any;
  superAdminLocalStorage: any;
  accessToken: any;
  submitted = false;
  invalid = false;
  loader = false;
  skypePassword: any;
  joiningDate: any;
  genderArray=[{name:"Male", value:1},{name:"Female", value:2},{name:"Other", value:3}];
  constructor(private toastr: ToastrService,private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {}


  onValueChange(event){
    this.genderSelected= event.target.value;
  }

  add(name: any, mobileNumber:any, email: any) {
  
    if (!name) {
      this.toastr.error('Please fill all the fields!');
      return;
    }
    this.loader = true;
    this.formData = {
      firstName: name,
      mobileNumber: mobileNumber,
      email: email
    };
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'addDoctor', this.formData, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        this.toastr.success('Successfully Added!');
        this.router.navigate(['/doctors']);
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });

  }

}
