import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-patient',
  templateUrl: './update-patient.component.html',
  styleUrls: ['./update-patient.component.css']
})
export class UpdatePatientComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
