import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { AppConstants } from '../api-constants';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { Daterangepicker } from 'ng2-daterangepicker';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {

  daterange: any = {};
  options: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
  };
 
  selectedDate(value: any, datepicker?: any) {
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end; 
    // use passed valuable to update state
    this.daterange.start = moment(new Date(value.start._d)).format("DD/MM/YYYY");
    this.daterange.end =  moment(new Date(value.end._d)).format("DD/MM/YYYY");
    localStorage.setItem('selectedStartEndDateBookingPage', JSON.stringify({startDate: this.daterange.start, endDate:this.daterange.end}));
  } 

  IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  accessToken;
  res: any;
  usersArray = [];
  apiBaseURL: string;
  superAdminLocalStorage;
  invalid = false;
  loader = false;
  userType;
  data: any[];
  dtOptions: DataTables.Settings = {};
  draw: number;
  recordsFiltered: number;
  selectedDateRangeLocalBookingPage: any;
  recordsTotal: number;
  constructor(private toastr: ToastrService, private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {
    localStorage.removeItem('selectedDoctorIdForReport');
    localStorage.removeItem('selectedStartEndDate');
    this.selectedDateRangeLocalBookingPage= JSON.parse(localStorage.getItem('selectedStartEndDateBookingPage'));
    if(this.selectedDateRangeLocalBookingPage){
      this.options.startDate= moment(this.selectedDateRangeLocalBookingPage.startDate).format('MM-DD-YYYY');
      this.options.endDate= moment(this.selectedDateRangeLocalBookingPage.endDate).format('MM-DD-YYYY');
      this.getAllFees(this.selectedDateRangeLocalBookingPage.startDate, this.selectedDateRangeLocalBookingPage.endDate);
    }
    else{
      this.getAllFees("","");
    }
  }

  applyFilters(){
    window.location.reload();
  }

    
  clearData(){
    localStorage.removeItem('selectedDoctorIdForReport');
    localStorage.removeItem('selectedStartEndDate');
    localStorage.removeItem('selectedStartEndDateBookingPage');
    window.location.reload();
  }

  downloadYesterdayReport(){
    this.loader = true;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'downloadYesterdayReport', {}, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        window.open(this.res.data.Location, '_blank'); 
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });
  }


  
  getAllFees(startDate="", endDate="") {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.loader = true;
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.accessToken);
        if(startDate !== ""){
          dataTablesParameters.startDate= startDate;
        }
        if(endDate !== ""){
          dataTablesParameters.endDate= endDate;
        }
        this.http.post(this.apiBaseURL + 'getBookingListToday', dataTablesParameters, { headers })
          .subscribe(
            res => {
              this.loader = false;
              this.res = res;     
              if(this.res.status == 0){
                this.toastr.error('Your session has been expired, please login again!');
                localStorage.removeItem('timeFilterValue');
                localStorage.removeItem('dateFilterValue');
                localStorage.removeItem('selectedDoctorIdForReport');
                localStorage.removeItem('selectedStartEndDate');
                localStorage.removeItem('SuperAdminData');
                localStorage.removeItem('UserSearchType');
                this.router.navigate(['/login']); 
              }           
              this.usersArray = this.res.data;
              callback({
                recordsTotal: this.res.count,
                recordsFiltered: this.res.count,
                data: []
              });
            });
      },
    };
  }


  downloadReport(){
    this.loader = true;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'downloadReportToday', {}, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if(this.res.status == 0){
        this.toastr.error('Your session has been expired, please login again!');
        localStorage.removeItem('timeFilterValue');
        localStorage.removeItem('dateFilterValue');
        localStorage.removeItem('selectedDoctorIdForReport');
        localStorage.removeItem('selectedStartEndDate');
        localStorage.removeItem('SuperAdminData');
        localStorage.removeItem('UserSearchType');
        this.router.navigate(['/login']); 
      }      
      if (this.res.status == 1) {
        window.open(this.res.data.Location, '_blank'); 
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });
  }


  
  
  delete(id) {
    if (confirm('Do you really want to delete this user?')) {
      this.loader = true;
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.accessToken);
      this.http.put(this.apiBaseURL + 'bookingRecords', { patientId: id, isDeleted: 1 }, { headers })
        .subscribe(
          res => {
            this.loader = false;
            this.res = res;
            if(this.res.status == 0){
              this.toastr.error('Your session has been expired, please login again!');
              localStorage.removeItem('timeFilterValue');
              localStorage.removeItem('dateFilterValue');
              localStorage.removeItem('selectedDoctorIdForReport');
              localStorage.removeItem('selectedStartEndDate');
              localStorage.removeItem('SuperAdminData');
              localStorage.removeItem('UserSearchType');
              this.router.navigate(['/login']); 
            }      
            if (this.res.status == 1) {
              this.toastr.success('Successfully deleted!');
              this.usersArray = this.usersArray.filter(function( obj ) {
                return obj._id !== id;
              });
            } else {
              this.loader = false;
              this.toastr.error('Something went wrong!');
            }
          });
    } else {

    }
  }


  printBill(id) {
    this.loader = true;
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.accessToken);
      this.http.post(this.apiBaseURL + 'getBillReport', { bookingId: id}, { headers })
        .subscribe(
          res => {
            this.loader = false;
            this.res = res;
            if(this.res.status == 0){
              this.toastr.error('Your session has been expired, please login again!');
              localStorage.removeItem('timeFilterValue');
              localStorage.removeItem('dateFilterValue');
              localStorage.removeItem('selectedDoctorIdForReport');
              localStorage.removeItem('selectedStartEndDate');
              localStorage.removeItem('SuperAdminData');
              localStorage.removeItem('UserSearchType');
              this.router.navigate(['/login']); 
            }      
            if (this.res.status == 1) {
              window.open(this.res.data.Location, '_blank'); 
              // this.toastr.success('Successfully uploaded!');
              // this.getUserDetail();
            } else {
              this.loader = false;
              this.toastr.error('Something went wrong!');
            }
          });
  }

}

