import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders,HttpClientModule  } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { AppConstants } from '../api-constants';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { Daterangepicker } from 'ng2-daterangepicker';
import { Location } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  daterange: any = {};
  options: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
  };
 
  selectedDate(value: any, datepicker?: any) {
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end; 
    // use passed valuable to update state
    this.daterange.start = moment(new Date(value.start._d)).format("DD/MM/YYYY");
    this.daterange.end =  moment(new Date(value.end._d)).format("DD/MM/YYYY");
    localStorage.setItem('selectedStartEndDate', JSON.stringify({startDate: this.daterange.start, endDate:this.daterange.end}));
  } 

  IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  accessToken;
  selectedItemsDoctors= [];
  selectedItemsPayment= [];
  selectedDoctorIdsLocalStorage: any;
  selectedDoctorIds= [];
  res: any;
  usersArray = [];
  doctorsArray: any;
  selectedDoctorIdLocal: any;
  selectedPaymentModes: any;
  selectedDateRangeLocal: any;
  selectedDoctorId="";
  optionObj: any;
  apiBaseURL: string;
  superAdminLocalStorage;
  invalid = false;
  loader = false;
  userType;
  data: any[];
  dtOptions: DataTables.Settings = {};
  draw: number;
  recordsFiltered: number;
  selectedPaymnetModes: any;
  selectedPaymentMethodsLocalStorage: any;
  dropdownSettingsDoctor: any;
  dropdownSettingsModels: any;
  recordsTotal: number;
  paymentModeArray=[{name:"Cash", value:1},{name:"Online", value:2},{name:"Cash/Online", value:3},{name:"Card", value:4},{name:"Credit", value:5}];

  constructor(private toastr: ToastrService, private http: HttpClient, private router: Router,private location: Location) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {  

      this.selectedDoctorIdLocal= JSON.parse(localStorage.getItem('selectedDoctorIdForReport'));
    
      this.selectedDateRangeLocal= JSON.parse(localStorage.getItem('selectedStartEndDate'));

      this.selectedPaymentModes= JSON.parse(localStorage.getItem('selectedPaymentMethodsForReport'));

      console.log("this.selectedPaymentModes========,",this.selectedPaymentModes)
      if(this.selectedPaymentModes){
        for(let ps of this.selectedPaymentModes){
        this.selectedItemsPayment.push({name: ps.name, value: ps.value});
      }
    }
      if(this.selectedDoctorIdLocal && !this.selectedDateRangeLocal){
        for(let ds of this.selectedDoctorIdLocal){
        this.selectedItemsDoctors.push({firstName:ds.name, _id: ds.doctorId})
        }
        this.getAllFees(this.selectedDoctorIdLocal,"","",this.selectedPaymentModes);
      }
      else if(this.selectedDoctorIdLocal && this.selectedDateRangeLocal){
        for(let ds of this.selectedDoctorIdLocal){
          this.selectedItemsDoctors.push({firstName:ds.name, _id: ds.doctorId})
          }
        this.options.startDate= moment(this.selectedDateRangeLocal.startDate).format('MM-DD-YYYY');
        this.options.endDate= moment(this.selectedDateRangeLocal.endDate).format('MM-DD-YYYY');
        this.getAllFees(this.selectedDoctorIdLocal,this.selectedDateRangeLocal.startDate, this.selectedDateRangeLocal.endDate,this.selectedPaymentModes);
      }
      else if(!this.selectedDoctorIdLocal && this.selectedDateRangeLocal){
        this.options.startDate= moment(this.selectedDateRangeLocal.startDate).format('MM-DD-YYYY');
        this.options.endDate= moment(this.selectedDateRangeLocal.endDate).format('MM-DD-YYYY');
        this.getAllFees("",this.selectedDateRangeLocal.startDate, this.selectedDateRangeLocal.endDate,this.selectedPaymentModes);
      }
      else{
        this.getAllFees("","","",this.selectedPaymentModes);
      }
      this.getDoctors();
      this.dropdownSettingsDoctor = {
        singleSelection: false,
        idField: '_id',
        textField: 'firstName',
       // placeholder:'Select Doctors',
        //selectAllText: 'Select All',
        //unSelectAllText: 'UnSelect All',
        itemsShowLimit: 10,
        allowSearchFilter: true
      };
      this.dropdownSettingsModels = {
        singleSelection: false,
        idField: 'value',
        textField: 'name',
       // placeholder:'Select Doctors',
        //selectAllText: 'Select All',
        //unSelectAllText: 'UnSelect All',
        itemsShowLimit: 10,
        allowSearchFilter: true
      };
  }

  applyFilters(){
    window.location.reload();
  }

  downloadTodayReport(){
    this.loader = true;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'downloadReportToday', {}, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if(this.res.status == 0){
        this.toastr.error('Your session has been expired, please login again!');
        localStorage.removeItem('timeFilterValue');
        localStorage.removeItem('dateFilterValue');
        localStorage.removeItem('selectedDoctorIdForReport');
        localStorage.removeItem('selectedStartEndDate');
        localStorage.removeItem('SuperAdminData');
        localStorage.removeItem('UserSearchType');
        this.router.navigate(['/login']); 
      }      
      if (this.res.status == 1) {
        window.open(this.res.data.Location, '_blank'); 
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });
  }

  downloadYesterdayReport(){
    this.loader = true;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'downloadYesterdayReport', {}, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if(this.res.status == 0){
        this.toastr.error('Your session has been expired, please login again!');
        localStorage.removeItem('timeFilterValue');
        localStorage.removeItem('dateFilterValue');
        localStorage.removeItem('selectedDoctorIdForReport');
        localStorage.removeItem('selectedStartEndDate');
        localStorage.removeItem('SuperAdminData');
        localStorage.removeItem('UserSearchType');
        this.router.navigate(['/login']); 
      }      
      if (this.res.status == 1) {
        window.open(this.res.data.Location, '_blank'); 
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });
  }



  
  onItemSelectDoctors(item: any) {
    this.selectedDoctorIds= [];
    this.selectedDoctorIdsLocalStorage= [];
    for (const c of this.selectedItemsDoctors) {
      this.selectedDoctorId=c._id;
      this.selectedDoctorIds.push(c._id);
      this.selectedDoctorIdsLocalStorage.push({doctorId: c._id, name:c.firstName});
    }
    localStorage.setItem('selectedDoctorIdForReport', JSON.stringify(this.selectedDoctorIdsLocalStorage)); 
    console.log("this.location.path()--",this.location.path())
    this.location.go(this.location.path());
  }

  
  onItemSelectPayment(item: any) {
    console.log("selectedItemsPayment---", this.selectedItemsPayment)
    this.selectedPaymnetModes= [];
    this.selectedPaymentMethodsLocalStorage= [];
    for (const c of this.selectedItemsPayment) {
      this.selectedPaymnetModes.push(c._id);
      this.selectedPaymentMethodsLocalStorage.push({name: c.name, value:c.value});
    }
    localStorage.setItem('selectedPaymentMethodsForReport', JSON.stringify(this.selectedPaymentMethodsLocalStorage)); 
  }
  
  onDeSelectPayment(item: any) {
    this.selectedDoctorIds= [];
    this.selectedPaymentMethodsLocalStorage= [];
    for (const c of this.selectedItemsPayment) {
      this.selectedDoctorId=c._id;
      this.selectedPaymnetModes.push(c._id);
      this.selectedPaymentMethodsLocalStorage.push({name: c.name, value:c.value});
    }
    localStorage.setItem('selectedPaymentMethodsForReport', JSON.stringify(this.selectedPaymentMethodsLocalStorage)); 
  }

  
  clearData(){
    localStorage.removeItem('selectedDoctorIdForReport');
    localStorage.removeItem('selectedStartEndDate');
    localStorage.removeItem('selectedPaymentMethodsForReport');
    window.location.reload();
  }
  onDeSelectDoctors(items: any) {
    this.selectedDoctorIds= [];
    this.selectedDoctorIdsLocalStorage= [];
    for (const c of this.selectedItemsDoctors) {
      this.selectedDoctorId=c._id;
      this.selectedDoctorIds.push(c._id);
      this.selectedDoctorIdsLocalStorage.push({doctorId: c._id, name:c.firstName});
    }
    localStorage.setItem('selectedDoctorIdForReport', JSON.stringify(this.selectedDoctorIdsLocalStorage));
  }


  getDoctors(){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'doctors', {skip:0, limit:10, type:"all"}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;  
          if(this.res.status == 0){
            this.toastr.error('Your session has been expired, please login again!');
            localStorage.removeItem('timeFilterValue');
            localStorage.removeItem('dateFilterValue');
            localStorage.removeItem('selectedDoctorIdForReport');
            localStorage.removeItem('selectedStartEndDate');
            localStorage.removeItem('SuperAdminData');
            localStorage.removeItem('UserSearchType');
            this.router.navigate(['/login']); 
          }              
          this.doctorsArray = this.res.data;
        });
}

  getAllFees(doctorId="", startDate="", endDate="",paymentModes=[]) {

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 20,
      serverSide: true,
      processing: true,
      searching: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.loader = true;
        let headers = new HttpHeaders();
        if(doctorId !== ""){
          dataTablesParameters.doctorId= doctorId;
        }
        if(startDate !== ""){
          dataTablesParameters.startDate= startDate;
        }
        if(endDate !== ""){
          dataTablesParameters.endDate= endDate;
        }
        if(paymentModes){
          dataTablesParameters.paymentModes= paymentModes;
        }
        headers = headers.set('Authorization', this.accessToken);
        this.http.post(this.apiBaseURL + 'bookingRecords', dataTablesParameters, { headers })
          .subscribe(
            res => {
              this.loader = false;
              this.res = res; 
              if(this.res.status == 0){
                this.toastr.error('Your session has been expired, please login again!');
                localStorage.removeItem('timeFilterValue');
                localStorage.removeItem('dateFilterValue');
                localStorage.removeItem('selectedDoctorIdForReport');
                localStorage.removeItem('selectedStartEndDate');
                localStorage.removeItem('SuperAdminData');
                localStorage.removeItem('UserSearchType');
                this.router.navigate(['/login']); 
              }               
              this.usersArray = this.res.data;
              callback({
                recordsTotal: this.res.count,
                recordsFiltered: this.res.count,
                data: []
              });
            });
      },
    };
}


  downloadReport(){
    this.loader = true;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.selectedDoctorIdLocal= JSON.parse(localStorage.getItem('selectedDoctorIdForReport'));
    this.selectedDateRangeLocal= JSON.parse(localStorage.getItem('selectedStartEndDate'));
    this.selectedPaymentModes= JSON.parse(localStorage.getItem('selectedPaymentMethodsForReport'));
    this.optionObj={};
    if(this.selectedDoctorIdLocal){
      this.optionObj.doctorId= this.selectedDoctorIdLocal;
    }
    if(this.selectedPaymentModes){
      this.optionObj.paymentModes= this.selectedPaymentModes;
    }
    if(this.selectedDateRangeLocal){
      this.optionObj.startDate= this.selectedDateRangeLocal.startDate;
      this.optionObj.endDate= this.selectedDateRangeLocal.endDate;
    }

    this.http.post(this.apiBaseURL + 'downloadReport', this.optionObj, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if(this.res.status == 0){
        this.toastr.error('Your session has been expired, please login again!');
        localStorage.removeItem('timeFilterValue');
        localStorage.removeItem('dateFilterValue');
        localStorage.removeItem('selectedDoctorIdForReport');
        localStorage.removeItem('selectedStartEndDate');
        localStorage.removeItem('SuperAdminData');
        localStorage.removeItem('UserSearchType');
        this.router.navigate(['/login']); 
      }      
      if (this.res.status == 1) {
        window.open(this.res.data.Location, '_blank'); 
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });
  }


}

