import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Http } from '@angular/http';
import { AppConstants } from '../api-constants';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  IMAGE_BASE_URL = AppConstants.imageBaseUrl;
  addStudentForm: any;
  formData: any;
  firstName: any;
  imageUploaded= false;
  lastName: any;
  email: any;
  imageSrc: any;
  res: any;
  err: any;
  password: any;
  imagePath="";
  contactNumber: any;
  address: any;
  userRole: any;
  employeeId: any;
  cyberomId: any;
  cyberomPassword: any;
  userType: any;
  selectedClassId= "";
  userDepartment: any;
  classesArray: any;
  skypeId: any;
  apiBaseURL: any;
  userImage: any;
  superAdminLocalStorage: any;
  accessToken: any;
  submitted = false;
  invalid = false;
  loader = false;
  skypePassword: any;
  joiningDate: any;
  constructor(private toastr: ToastrService,private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {
  }


  add(name: any) {
  
    if (!name) {
      this.toastr.error('Please fill all the fields!');
      return;
    }
    this.loader = true;
    this.formData = {
      name: name,
      image: this.imagePath
    };
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'addCategory', this.formData, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        this.toastr.success('Successfully Added!');
        this.router.navigate(['/categories']);
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });

  }

  
  processFileimage(imageInput: any) {
    this.loader = true;
    const formData: any = new FormData();
    const fileName: any = imageInput.target.files[0];
    formData.append('myfile', fileName);
    const idxDot = imageInput.target.files[0].name.lastIndexOf('.') + 1;
    const extFile = imageInput.target.files[0].name.substr(idxDot, imageInput.target.files[0].name.length).toLowerCase();
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(fileName);
      this.http.post(this.apiBaseURL+"upload", formData).subscribe(
        res => {
        this.loader = false;
        this.res= res;
        this.imagePath =this.res.Location;
        this.imageUploaded=true;
        console.log("this.imagePath",this.IMAGE_BASE_URL)
      },
        err => {
          this.loader = false;
          console.log('Error', err.error.message);
          this.err = err;
        },
      );


  }

  


}
