import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Components/login/login.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';


import { SidebarComponent } from './Components/sidebar/sidebar.component';
import { HeaderComponent } from './Components/header/header.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';

import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './Components/loader/loader.component';
import { CategoryComponent } from './Components/category/category.component';
import { AddCategoryComponent } from './Components/add-category/add-category.component';
import { UpdateCategoryComponent } from './Components/update-category/update-category.component';
import { TestsComponent } from './Components/tests/tests.component';
import { AddTestComponent } from './Components/add-test/add-test.component';
import { UpdateTestComponent } from './Components/update-test/update-test.component';
import { PatientsComponent } from './Components/patients/patients.component';
import { AddPatientComponent } from './Components/add-patient/add-patient.component';
import { UpdatePatientComponent } from './Components/update-patient/update-patient.component';
import { AddDoctorComponent } from './Components/add-doctor/add-doctor.component';
import { UpdateDoctorComponent } from './Components/update-doctor/update-doctor.component';
import { DoctorsComponent } from './Components/doctors/doctors.component';
import { BookingsComponent } from './Components/bookings/bookings.component';
import { AddBookingComponent } from './Components/add-booking/add-booking.component';
import { UpdateBookingComponent } from './Components/update-booking/update-booking.component';
import { BookingDetailsComponent } from './Components/booking-details/booking-details.component';
import { PackagesComponent } from './Components/packages/packages.component';
import { AddPackageComponent } from './Components/add-package/add-package.component';
import { UpdatePackageComponent } from './Components/update-package/update-package.component';
import { CorporateComponent } from './Components/corporate/corporate.component';
import { AddCorporateComponent } from './Components/add-corporate/add-corporate.component';
import { UpdateCorporateComponent } from './Components/update-corporate/update-corporate.component';
import { ReportsComponent } from './Components/reports/reports.component';
import { Daterangepicker } from 'ng2-daterangepicker';
import { LabBookingsComponent } from './Components/lab-bookings/lab-bookings.component';
import { LabBookingDetailsComponent } from './Components/lab-booking-details/lab-booking-details.component';
import { ComBookingsComponent } from './Components/com-bookings/com-bookings.component';
import { ActivitiesComponent } from './Components/activities/activities.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    HeaderComponent,
    LoaderComponent,
    DashboardComponent,
    CategoryComponent,
    AddCategoryComponent,
    UpdateCategoryComponent,
    TestsComponent,
    AddTestComponent,
    UpdateTestComponent,
    PatientsComponent,
    AddPatientComponent,
    UpdatePatientComponent,
    AddDoctorComponent,
    UpdateDoctorComponent,
    DoctorsComponent,
    BookingsComponent,
    AddBookingComponent,
    UpdateBookingComponent,
    BookingDetailsComponent,
    PackagesComponent,
    AddPackageComponent,
    UpdatePackageComponent,
    CorporateComponent,
    AddCorporateComponent,
    UpdateCorporateComponent,
    ReportsComponent,
    LabBookingsComponent,
    LabBookingDetailsComponent,
    ComBookingsComponent,
    ActivitiesComponent    
  ],
  imports: [
    BrowserModule,
    ToastrModule.forRoot(),
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    DataTablesModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    Daterangepicker
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
