import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import { Http } from '@angular/http';
import { AppConstants } from '.././api-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit { 
  apiBaseURL: string;
  loginform: FormGroup;
  submitted: boolean;
  formdata: { email: any; password: any; deviceType: string; deviceToken: string; };
  superAdminLocalStorage: any;
  invalid = false;
  loader = false;
  email: any;
  password: any;
  fcm_token: any;
  constructor(private toastr: ToastrService,  private http: Http, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    if (this.superAdminLocalStorage) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.loginform = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
      password: new FormControl('', [Validators.required])
    });
  }

  get f() { return this.loginform.controls; }


  adminLogin(data: { email: any; password: any; }) {
    this.submitted = true;
    if (this.loginform.invalid) {
      return;
    }
    this.loader = true;
    this.formdata = {
      email: data.email,
      password: data.password,
      deviceType: 'WEB',
      deviceToken: 'xxxxxxxxxxxx'
    };

    this.http.post(this.apiBaseURL + 'login', this.formdata).map((res) => res.json()).subscribe((data) => {
      this.loader = false;
       if (data.status === 1) {
        localStorage.setItem('SuperAdminData', JSON.stringify({ data: data.data, token: data.token, type: data.data.type}));
        this.toastr.success('Logged-in successfully!');
        if(data.data.type == 1){
        this.router.navigate(['/dashboard']);
        }
        else if(data.data.type == 2){
          this.router.navigate(['/lab-bookings']); 
        }
        else if(data.data.type == 3){
          this.router.navigate(['/com-bookings']);
        }
      } else {
        this.loader = false;
        this.toastr.error('Email or password is incorrect!');
        this.invalid = true;
      }
    });
  }

}
