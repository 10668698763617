import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './Components/login/login.component';
import { SidebarComponent } from './Components/sidebar/sidebar.component';
import { HeaderComponent } from './Components/header/header.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { CategoryComponent } from './Components/category/category.component';
import { AddCategoryComponent } from './Components/add-category/add-category.component';
import { UpdateCategoryComponent } from './Components/update-category/update-category.component';
import { TestsComponent } from './Components/tests/tests.component';
import { AddTestComponent } from './Components/add-test/add-test.component';
import { UpdateTestComponent } from './Components/update-test/update-test.component';
import { PatientsComponent } from './Components/patients/patients.component';
import { AddPatientComponent } from './Components/add-patient/add-patient.component';
import { UpdatePatientComponent } from './Components/update-patient/update-patient.component';
import { AddDoctorComponent } from './Components/add-doctor/add-doctor.component';
import { UpdateDoctorComponent } from './Components/update-doctor/update-doctor.component';
import { DoctorsComponent } from './Components/doctors/doctors.component';
import { BookingsComponent } from './Components/bookings/bookings.component';
import { AddBookingComponent } from './Components/add-booking/add-booking.component';
import { UpdateBookingComponent } from './Components/update-booking/update-booking.component';
import { BookingDetailsComponent } from './Components/booking-details/booking-details.component';
import { PackagesComponent } from './Components/packages/packages.component';
import { AddPackageComponent } from './Components/add-package/add-package.component';
import { UpdatePackageComponent } from './Components/update-package/update-package.component';
import { CorporateComponent } from './Components/corporate/corporate.component';
import { AddCorporateComponent } from './Components/add-corporate/add-corporate.component';
import { UpdateCorporateComponent } from './Components/update-corporate/update-corporate.component';
import { ReportsComponent } from './Components/reports/reports.component';

import { LabBookingsComponent } from './Components/lab-bookings/lab-bookings.component';
import { LabBookingDetailsComponent } from './Components/lab-booking-details/lab-booking-details.component';

import { ComBookingsComponent } from './Components/com-bookings/com-bookings.component';
import { ActivitiesComponent } from './Components/activities/activities.component';



const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'sidebar', component: SidebarComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'categories', component: CategoryComponent },
  { path: 'add-category', component: AddCategoryComponent },
  { path: 'update-category/:id', component: UpdateCategoryComponent },
  { path: 'tests', component: TestsComponent },
  { path: 'add-test', component: AddTestComponent },
  { path: 'update-test/:id', component: UpdateTestComponent },
  { path: 'patients', component: PatientsComponent },
  { path: 'add-patient', component: AddPatientComponent },
  { path: 'update-patient/:id', component: UpdatePatientComponent },
  { path: 'doctors', component: DoctorsComponent },
  { path: 'add-doctor', component: AddDoctorComponent },
  { path: 'update-doctor/:id', component: UpdateDoctorComponent },
  { path: 'bookings', component: BookingsComponent },
  { path: 'lab-bookings', component: LabBookingsComponent },
  { path: 'lab-booking-details/:id', component: LabBookingDetailsComponent },
  { path: 'add-booking', component: AddBookingComponent },
  { path: 'update-booking/:id', component: UpdateBookingComponent },
  { path: 'booking-details/:id', component: BookingDetailsComponent },

  { path: 'packages', component: PackagesComponent },
  { path: 'add-package', component: AddPackageComponent },
  { path: 'update-package/:id', component: UpdatePackageComponent },

  { path: 'reports', component: ReportsComponent },
  { path: 'com-bookings', component: ComBookingsComponent },


  { path: 'corporates', component: CorporateComponent },
  { path: 'activities', component: ActivitiesComponent },
  { path: 'add-corporate', component: AddCorporateComponent },
  { path: 'update-corporate/:id', component: UpdateCorporateComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
