import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router,ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';
import { Http } from '@angular/http';
import { AppConstants } from '../api-constants';


@Component({
  selector: 'app-update-test',
  templateUrl: './update-test.component.html',
  styleUrls: ['./update-test.component.css']
})
export class UpdateTestComponent implements OnInit {
  addStudentForm: any;
  formData: any;
  firstName: any;
  fullPath: any;
  lastName: any;
  email: any;
  imageSrc: any;
  res: any;
  err: any;
  password: any;
  contactNumber: any;
  address: any;
  userRole: any;
  employeeId: any;
  cyberomId: any;
  cyberomPassword: any;
  selectedClassId ="";
  selectedValue= "";
  selectedStatusValue= "";
  userType: any;
  IMAGE_BASE_URL:any;
  userDepartment: any;
  skypeId: any;
  apiBaseURL: any;
  selectedUsers: any;
  userDetails: any;
  classesArray: any;
  userImage: any;
  imagePath: any;
  superAdminLocalStorage: any;
  accessToken: any;
  selectedItems: any;
  submitted = false;
  invalid = false;
  loader = false;
  skypePassword: any;
  dropdownSettings: any = {};
  joiningDate: any;
  selectedCateoryId="";
  passFailArray= [{name: "Pass", value: 1},{name: "Fail", value: 2}];
  constructor(private toastr: ToastrService,private route: ActivatedRoute,private http: HttpClient, private router: Router) {
    this.apiBaseURL = AppConstants.baseURL;
    this.IMAGE_BASE_URL = AppConstants.imageBaseUrl;
    this.superAdminLocalStorage = JSON.parse(localStorage.getItem('SuperAdminData'));
    this.accessToken = this.superAdminLocalStorage.token;
  }

  ngOnInit() {
    this.getUserDetail();
    this.getCategories();
    this.dropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'name',
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      itemsShowLimit: 100,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    this.selectedUsers = [];
    for (const c of this.selectedItems) {
      this.selectedUsers.push(c._id);
      this.selectedCateoryId= c._id
    }
  }
  onDeSelect(item: any) {
    this.selectedCateoryId= "";
  }




  getCategories(){
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.post(this.apiBaseURL + 'category', {skip:0, limit:10}, { headers })
      .subscribe(
        res => {
          this.loader = false;
          this.res = res;          
          this.classesArray = this.res.data;
        });
}

  onValueChange(event){
    this.selectedValue= event.target.value;
  }



  getUserDetail() {   
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.loader= true;
    this.http.post(this.apiBaseURL + 'testDetails', {_id: this.route.snapshot.params.id},{headers}).subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        this.selectedItems= [];
        this.userDetails= this.res.data;
        this.selectedCateoryId= this.userDetails.categoryId._id;
        this.selectedItems.push({name:this.userDetails.categoryId.name, _id: this.userDetails.categoryId._id });
        console.log("this.selectedItems---", this.selectedItems);
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
      }
    });
  }

  update(name: any,amount: any) {
    if (!name || !amount || !this.selectedCateoryId) {
      this.toastr.error('Please fill all the fields!');
      return;
    }
    this.loader = true;
    this.formData = {
      testId: this.route.snapshot.params.id,
      name: name,
      amount: amount,
      categoryId: this.selectedCateoryId
    };
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.accessToken);
    this.http.put(this.apiBaseURL + 'tests', this.formData, { headers })
    .subscribe(
      res => {
      this.loader = false;
      this.res= res;
      if (this.res.status == 1) {
        this.toastr.success('Successfully Updated!');
        this.getUserDetail();
      } else {
        this.loader = false;
        this.toastr.error('Something went wrong!');
        this.invalid = true;
      }
    });

  }

  

}

